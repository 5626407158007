var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-col",
                    [
                      _c("h1", [_vm._v("Playlist aanmaken")]),
                      _c("page-snippet", {
                        attrs: { name: "release-create-intro" }
                      }),
                      _c(
                        "b-card",
                        {
                          staticClass: "mt-4",
                          attrs: { header: "Nieuwe playlist" }
                        },
                        [
                          !_vm.labelsLoaded || _vm.form.saving
                            ? _c("b-spinner", { attrs: { label: "Spinning" } })
                            : _vm._e(),
                          !_vm.form.saving && _vm.labelsLoaded
                            ? _c(
                                "b-form",
                                {
                                  on: {
                                    submit: function($event) {
                                      $event.preventDefault()
                                      return _vm.onSubmit($event)
                                    }
                                  }
                                },
                                [
                                  _c("form-field", {
                                    attrs: {
                                      field: _vm.form.fields["SubProviderID"],
                                      form: _vm.form,
                                      name: "SubProviderID"
                                    }
                                  }),
                                  _c("form-field", {
                                    attrs: {
                                      field: _vm.form.fields["Title"],
                                      form: _vm.form,
                                      name: "Title"
                                    }
                                  }),
                                  _c("page-snippet", {
                                    attrs: { name: "rechten-generiek" }
                                  }),
                                  _c("form-field", {
                                    attrs: {
                                      field:
                                        _vm.form.fields["DefaultTerritory"],
                                      form: _vm.form,
                                      name: "DefaultTerritory"
                                    }
                                  }),
                                  _vm.form.values.DefaultTerritory == "none"
                                    ? _c("page-snippet", {
                                        attrs: { name: "territory" }
                                      })
                                    : _vm._e(),
                                  _c(
                                    "b-button",
                                    {
                                      attrs: {
                                        type: "submit",
                                        variant: "primary"
                                      }
                                    },
                                    [_vm._v("Start")]
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }