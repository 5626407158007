<template>
  <div>
    <b-container fluid>
      <b-row>
        <b-col>
          <b-col>
            <h1>Playlist aanmaken</h1>
            <page-snippet name="release-create-intro" />

            <b-card header="Nieuwe playlist" class="mt-4">
              <b-spinner label="Spinning" v-if="!labelsLoaded || form.saving"></b-spinner>

              <b-form v-if="!form.saving && labelsLoaded" @submit.prevent="onSubmit">
                <form-field :field="form.fields['SubProviderID']" :form="form" name="SubProviderID" /> 
                <form-field :field="form.fields['Title']" :form="form" name="Title" /> 

                <page-snippet name="rechten-generiek" />
                <form-field :field="form.fields['DefaultTerritory']" :form="form" name="DefaultTerritory" /> 

                <page-snippet v-if="form.values.DefaultTerritory == 'none'" name="territory" />

                <b-button type="submit" variant="primary">Start</b-button>
              </b-form>
            </b-card>

          </b-col>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  import PageSnippet from '@/components/PageSnippet'
  import FormField from '@/components/FormField'

  export default {
    name: 'ReleaseConceptCreate',
    components: {
      PageSnippet,
      FormField
    },
    props: {
      id: String,
    },
    data () {
      return {
        labelsLoaded: false,
        form: {
          saving: false,
          values: {
            SubProviderID: '',
            Title: '',
          },
          validated: false,
          fields: {
            'SubProviderID': {
              label: 'Label',
              type: 'v-select',
              options: [],
              required: true,
            },
            'Title': {
              label: 'Werktitel',
              type: 'text',
              required: true,
            },
            'DefaultTerritory': {
              label: 'Rechten / Beschikbaarheid',
              type: 'v-select',
              required: true,
              options: [
                { key: '+WRD', label: 'Wereld'},
                { key: '+EEU', label: 'Europa'},
                { key: '+BEN', label: 'Benelux'},
                { key: '+NL', label: 'Nederland'},
                { key: 'none', label: 'Anders / opgeven per track'},
              ],
            },
          }        
        }
      }
    },
    methods: {
      onSubmit () {
        this.form.saving = true
        this.$http.post('release-concepts', this.form.values)
            .then((response) => {
              this.$router.push({ name: 'release-concepts', params: { id: response.data.ReleaseConceptID } })
            })
            .catch((error) => {
              console.log(error)
            })
      }
    },
    mounted () {
      // Load label options
      let ctx = {
        perPage: 10000,
        currentPage: 1,
      }
      this.$http
        .get('labels', { params: ctx })
        .then(response => {
          this.form.fields.SubProviderID.options = response.data.map(l => {
            return { key: l.SubProviderID, label: l.Name + ' ('+ l.LabelID +')'}
          })

          this.labelsLoaded = true
        })
    }
  }
</script>

<style scoped>

</style>
